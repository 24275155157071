import React, { useState, useRef } from "react"
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { Canvas, extend, useThree, useFrame } from "react-three-fiber"
import { useSpring, a } from "react-spring/three"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Head from "../components/head"

import canvsStyle from "../pages/index.module.scss"

extend({ OrbitControls })

const Controls = () => {
  const { camera, gl } = useThree()
  const ref = useRef()

  useFrame(() => {
    ref.current.update()
  })

  return (
    <orbitControls
      autoRotate
      maxPolarAngle={Math.PI / 3}
      minPolarAngle={Math.PI / 3}
      ref={ref}
      args={[camera, gl.domElement]}
    />
  )
}

const Plane = () => (
  <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]} receiveShadow>
    <planeBufferGeometry attach="geometry" args={[100, 100]} />
    <meshPhysicalMaterial attach="material" color="white" />
  </mesh>
)

const Box = () => {
  const [hover, setHover] = useState(false)
  const props = useSpring({
    color: hover ? "hotpink" : "grey",
  })

  return (
    <a.mesh
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
      castShadow
    >
      <ambientLight intensity={0.25} />
      <spotLight position={[0, 5, 10]} penumbra={1} castShadow />
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <a.meshPhysicalMaterial attach="material" color={props.color} />
    </a.mesh>
  )
}

const INDEX_PAGE = () => {
  return (
    <div>
      <Layout>
        <Head title="Home" />
        <div className={canvsStyle.glCanvas}>
          <Canvas
            camera={{ position: [0, 0, 5] }}
            onCreated={({ gl }) => {
              gl.shadowMap.enabled = true
              gl.shadowMap.type = THREE.PCFSoftShadowMap
            }}
          >
            <fog attach="fog" args={["white", 5, 30]} />
            <Controls />
            <Plane />
            <Box />
          </Canvas>
        </div>
        <p>
          This is just a place where I document my journey through learning
          about computer science. My interests include 3D graphics, physics
          simulations, and game development.
        </p>
      </Layout>
    </div>
  )
}

export default INDEX_PAGE
